.message-container {
    padding: 15px;
    min-height: 100vh;
}

.convos-list {
    margin: 20px 0 0 0;
}

.convo-item {
    padding: 10px;
    background: var(--blue-color);
    border-radius: 5px;
    margin: 5px 0 0 0;
}

.convo-receiver-photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.parti-username {
    font-size: 14px;
}

.last-message {
    color: var(--gray-color);
    padding: 5px;
    margin: 0 0 0 10px;
    width: 250px;
    display: block;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (min-width:526px) {}

@media (min-width:768px) {
    .message-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .convos-list {
        width: 50%;
    }
}