.posts-list {
    padding: 15px;
}


.userposts-text {
    color: var(--darkgray-color);
    font-size: 14px;
    border-top: 2px solid var(--darkgray-color);
    padding: 10px 10px;
}

.post-item {
    background: var(--white-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 0 2px var(--black-color);
    width: 100%;
    position: relative;
}

.post-options-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.options-container {
    background: var(--darkgray-color);
    width: 50%;
    position: absolute;
    top: 40px;
    right: 20px;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;

}

.options-item {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--darkgray-color);
    padding: 10px;
    border-bottom: 1px solid var(--white-color);


}

.delete-btn,
.edit-btn,
.share-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.delete-post-container {
    position: absolute;
    background: var(--warning-bg-color);
    padding: 10px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

.delete-post-container p {
    color: var(--white-color);
    padding: 10px;
}

.delete-post-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 9999;
}

.delete-post-btns button {
    padding: 5px;
    padding: 10px;
    cursor: pointer;


}

.yes-btn {
    color: var(--warning-bg-color);
}

.no-btn {
    color: var(--blue-color);
}

.link-container {
    width: 100%;
}

.post-user--photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-self: baseline;
}

.post-username {
    color: var(--white-color);
    background: var(--blue-color);
    padding: 2px 10px;
    border-radius: 10px;
}

.post-content {
    padding: 15px;
    font-weight: 700;
    color: var(--black-color);
    font-size: 14px;
}

.post-image-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-image {
    width: 200px;
}

.post-date,
.post-time {
    align-self: flex-end;
    font-size: 0.7rem;
    color: var(--black-color);
    font-weight: 700;
    opacity: 0.8;
}

/* Example of how to fade our content in */

.LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
}

.is-visible {
    opacity: 1;
}


@media (min-width:526px) {}

@media (min-width:768px) {

    .posts-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .LazyLoad {
        width: 50%;
        height: auto;
    }

    .MuiStack-root {
        width: 50%;
    }

    .post-item {
        width: 100%;
    }
}