.profile-container {
    padding: 15px;
    background: var(--white-color);
}

.profile-details-container {
    display: flex;
    align-items: flex-end;
    background: var(--blue-color);
    padding: 15px;
    border-radius: 5px;
    gap: 20px;
    height: 200px;
    margin: 10px 0;
    box-shadow: 0 0 5px var(--gray-color);
}

.edit-profile-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--white-color);
    color: var(--darkgraycolor);
    cursor: pointer;
    border-radius: 5px;
    border: 0;
    display: flex;
    align-items: center;
    padding: 2px 5px;
}

.logout-btn {
    position: absolute;
    padding: 5px 10px;
    background: var(--warning-bg-color);
    color: var(--white-color);
    border: 1px solid var(--warning-bg-color);
    cursor: pointer;
    border-radius: 5px;
    top: 50px;
    right: 20px;
}

.user-profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.details-text-container {
    display: flex;
    flex-direction: column;
}

.gender-icon {
    width: 20px;
    padding: 0px 0px;
    margin: 0 5px 0 0;
}

.profile-name {
    font-size: 26px;
    color: var(--white-color);
}

.profile-bio {
    font-size: 14px;
    color: var(--white-color);
    opacity: 0.8;
}


/* update profile */

.updateprofile-container {
    width: 80%;
    background: var(--white-color);
    z-index: 10;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px var(--black-color);
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.updatecancel-btn {
    align-self: flex-end;
    background: var(--warning-bg-color);
    color: var(--white-color);
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--warning-bg-color);
    padding: 2px 5px;

}

.updateProfile-form {
    position: relative;
}

.change-pass-form {
    position: relative;
}

.photoprev-container {
    width: 100%;
    text-align: center;

}

.photoprev-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;


}

.updateProfile-form {
    display: flex;
    flex-direction: column;

}

.updatedPhotoInput {
    width: 100%;
    border: 1px solid var(--black-color);
    padding: 5px;
    border-radius: 5px;
}

.updatedBioTextArea {
    border-radius: 5px;
    padding: 5px;
}

.updateProfile-btn {
    color: var(--white-color);
    background: var(--blue-color);
    padding: 10px;
    width: 100px;
    margin: 5px 0;
    border-radius: 5px;
    align-self: flex-end;
    cursor: pointer;
    border: 0;
    outline: 0;
}


.no-posts-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-posts {
    background: var(--darkgray-color);
    color: var(--white-color);
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    font-size: 14px;
}

/* change pass */
.change-pass-toogle-btn {
    background: var(--darkgray-color);
    color: var(--white-color);
    padding: 10px;
    cursor: pointer;
}

.change-pass-form {
    width: 100%;
    margin: 10px 0;
}

.form-input-update-pass {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.password-status-msg {
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: var(--warning-bg-color);
    font-weight: 600;

}

.form-input-update-pass label {
    font-size: 14px;
}

.form-input-update-pass input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid var(--black-color);
}

.form-input-update-pass input:focus {
    box-shadow: 0 0 5px var(--blue-color);
}

.change-pass-btn {
    background: var(--blue-color);
    color: var(--white-color);
    padding: 10px;
    cursor: pointer;
    border: 0;
    margin: 5px 0 0 0;
    border-radius: 5px;
    float: right;
}


/* logout-modal */

.logout-modal {
    position: absolute;
    background: var(--warning-bg-color);
    z-index: 99;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.logout-modal p {
    color: var(--white-color);
    text-align: center;
    font-size: 14px;
}


.logout-modal button {
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
}

.logout-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.logout-yes-btn {
    color: var(--warning-bg-color);
}

.logout-no-btn {
    color: var(--blue-color);
}

/* send message */

.send-msg-btn {
    color: var(--white-color);
    background: var(--blue-color);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--white-color);
    position: absolute;
    top: 10px;
    right: 10px;
}

.message-modal-container {
    padding: 10px;
    background: var(--blue-color);
    box-shadow: 0 0 4px var(--black-color);
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

}

.message-form {
    width: 300px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.message-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

.message-form button {
    align-self: flex-end;
    padding: 10px;
    color: var(--white-color);
    background: var(--blue-color);
    border: 1px solid var(--white-color);
    cursor: pointer;
    border-radius: 5px;
}


@media (min-width:526px) {}

@media (min-width:768px) {

    .profile-details-container {
        height: 300px;
    }

    .updateprofile-container {
        width: 40%;
    }
}