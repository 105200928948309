header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--light-color);
    padding: 10px;
    position: fixed;
    width: 100%;
    background: var(--blue-color);
    z-index: 100;
}

.icon {
    width: 40px;
    height: 40px;
    cursor: pointer;

}

.logo {
    width: 45px;
    border-radius: 50%;
}

.search-form-input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon {
    background: var(--white-color);
    height: 35px;
    border-radius: 25px 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}


.search-input {
    width: 100%;
    font-size: 14px;
    border-radius: 0 25px 25px 0;
    border: 1px solid var(--white-color);
    background: var(--white-color);
    outline: none;
    height: 35px;
}

.search-input :focus {
    border: 2px solid var(--white-color);
    outline: 0;
}

.profile-photo {
    border-radius: 50%;
}


.search-form {}

/* SEARCH BAR RESULT */

.broker {
    position: relative;
}

.result-container {
    background-color: var(--white-color);
    padding: 10px;
    width: 210px;
    margin: 5px 0 0 0;
    position: absolute;
    border-radius: 5px;
    top: 0px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
}

.search-result-text {
    color: var(--dark-gray);
    font-size: 14px;
    position: relative;

}

.search-photo {
    margin: 5px 0 0 0;
}

.search-name {
    font-size: 16px;
}

.result-item {
    padding: 5px;
}


@media (min-width:526px) {

    header {
        border-bottom: 2px solid var(--light-color);
        justify-content: end;
        gap: 20px;
        padding: 20px;
    }
    .search-form{
        width: 300px;
        margin-right: auto;
        
    }


}

@media (min-width:768px) {

    
}