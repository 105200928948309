.forgot-pass-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.reset-pass-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

.forgot-pass-container label {
    color: var(--warning-bg-color);
}

.reset-pass-form input {
    padding: 10px;
    font-size: 14px;
}

.reset-pass-form button {
    width: 200px;
    padding: 10px;
    background: var(--blue-color);
    border: 1px solid var(--blue-color);
    color: var(--white-color);
    align-self: flex-end;
    cursor: pointer;
}