.newpost-containter {
    padding: 95px 20px 0px 20px;

}

.post-btn {
    width: 100%;
    border-radius: 30px;
    background: var(--white-color);
    border: 1px solid var(--black-color);
    cursor: pointer;
    font-size: 14px;
    padding: 9px;
    opacity: 0.8;
}

.post-btn:hover {
    opacity: 1;
}

/* post pop up form */

.newpost-container-bg-container {
    position: relative;
}

.newpostfield-container-bg {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background: var(--black-color);
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);
}

.newpostfield-container {
    padding: 20px;
    position: absolute;
    width: 80%;
    height: auto;
    background: var(--white-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 0 5px var(--gray-color);
    z-index: 199;

}




.cancelpost-btn {
    align-self: flex-end;
    padding: 2px 5px;
    display: inline;
    border-radius: 5px;
    background: var(--warning-bg-color);
    color: var(--white-color);
    outline: 0;
    border: 1px solid var(--white-color);
    cursor: pointer;

}

.cancelpost-btn:hover {
    opacity: 0.8;
}

.newpost-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.form-textarea {
    width: 100%;
    background: var(--white-color);

}

.post-image-container {
    border: 2px solid var(--black-color);
    background: var(--darkgray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    padding: 25px;
    position: relative;

}


.remove-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: var(--warning-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;

}

.post-image-container img {
    width: 250px;
    border-radius: 5px;
    background: var(--white-color);


}

.form-textarea-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

.insert-photo-btn {
    align-self: flex-end;
}

.newpost-btn {
    width: 30%;
    align-self: flex-end;
    border-radius: 5px;
    padding: 10px;
    background: var(--blue-color);
    color: var(--white-color);
    outline: 0;
    border: 1px solid var(--white-color);
    cursor: pointer;
}

.newpost-btn:hover {
    opacity: 0.8;
}

.notify-posted {
    position: absolute;
    background: var(--success-bg-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    padding: 10px;
    color: var(--light-color);
    width: 300px;
    text-align: center;
    font-size: 0.8rem;
}


@media (min-width:526px) {

    .newpost-containter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 115px 50px 30px 50px;

    }

    .post-btn {
        width: 70%;
    }

    .newpostfield-container {
        width: 70%;
    }

    .form-textarea textarea {
        height: 100px;
    }

}

@media (min-width:768px) {
    .post-btn {
        width: 50%;
        padding: 10px;
    }

    .newpostfield-container {
        width: 50%;
        top: 50%;
    }


    .form-textarea textarea {
        height: 150px;
    }
}