.login-container {
    min-height: 100vh;
}

.login-container,
.login-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.welcome-text {
    color: var(--semi-dark-color);
    font-size: 20px;
}

.wrongdetails-alert {
    background: var(--warning-bg-color);
    color: var(--white-color);
    padding: 10px;
    border-radius: 10px;
    font-size: 10px;
}


.form-input-login {
    width: 100%;
    margin: 5px 0;
    display: flex;
    flex-direction: column;


}

.form-input-login label {
    margin: 5px;
    font-size: 16px;
    margin: 10px 0;
}

.form-input-login input {
    padding: 10px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid var(--light-color);
    border: 1px solid var(--gray-color);
}


.form-input-login input:focus {
    box-shadow: 0 0 5px var(--blue-color);
}

.login-btn {
    font-size: 14px;
    padding: 10px;
    letter-spacing: 2px;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
    background: var(--blue-color);
    color: var(--white-color);
    border: 1px solid var(--blue-color);
    margin: 10px 0;
    width: 100%;
}

.nyam-text {
    font-size: 14px;
}

.signup-link {
    color: var(--semi-dark-color);
    padding: 10px;
    margin: 10px 0;
}

/*spinner */

.spinner-container {
    display: absolute;
}


@media (min-width:526px) {

    .login-form{
        width: 50%;
    }

}


@media (min-width:526px) {

    .login-form{
        width: 50%;
    }

}

@media (min-width:768px) {

    .login-form{
        width: 30%;
    }

}