.convos-container {
    position: relative;
    padding: 0 10px;
    min-height: 100vh;
   

}

.convos-centered{
    border: 2px solid var(--blue-color);
    border-radius: 2px;
    height: 100vh;
    padding: 10px;
}
.receiver-divider {
    padding: 5px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;

}

.convo-messages-list {
    margin: 60px 0 20px 0;
    overflow-y: auto;
    height:400px;
}

.message-item {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.convo-receiver-photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.convo-receiver-username {
    font-size: 18px;

}

.sender-msg {
    color: var(--white-color);
    background: var(--blue-color);
    margin: 1px 0;
    padding: 5px 10px;
    border-radius: 50px;
    align-self: flex-end;
    font-size: 14px;
}

.receiver-msg {
    background: var(--black-color);
    color: var(--white-color);
    margin: 1px 0;
    padding: 5px 10px;
    border-radius: 50px;
    align-self: flex-start;
    font-size: 14px;
}

.refresh-btn {
    background: var(--success-bg-color)!important;
    color: var(--white-color);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--blue-color);
    cursor: pointer;
    align-self: flex-end;
    margin: 5px 0;
}

.convo-message-form {
    display: flex;
    flex-direction: column;

}

.convo-message-form textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 5px;
    border: 1px solid var(--black-color);
}

.convo-message-form button {
    background: var(--blue-color);
    color: var(--white-color);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--blue-color);
    cursor: pointer;
    align-self: flex-end;
    margin: 5px 0;

}


.bottom-btns{
    position: fixed;
    width: 90%;
    bottom: 0px;
}
@media (min-width:526px) {}

@media (min-width:768px) {
    
    .convos-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .convos-centered {
       width: 60%;
    }

    .bottom-btns{
        position: fixed;
        width: 55%;
        bottom: 0px;
    }
}